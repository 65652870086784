import {
  BodyParagraph,
  CopyrightContainer,
  CustomButtonFooter,
  FooterContainer,
  ImageBantuan,
  ImageCustom,
  ImagePembayaran,
  PaperBank,
  TitleParagraph,
} from "./Footer.styles";
import ContentPasteSearch from "@mui/icons-material/ContentPasteSearch";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { LeftBox } from "styles";
import { Link, useLocation } from "react-router-dom";
import { menuUrl } from "constants/";
import { useMediaQuery } from "react-responsive";
import { CustomButton } from "components";

function Footer() {
  const isMobile = useMediaQuery({ maxWidth: 440 });
  const { pathname } = useLocation();

  // const handleClickInstagram = () => {
  //   window.open("https://www.instagram.com/araaby_/", "_blank");
  // };

  const handleClickTiktok = () => {
    window.open("https://www.tiktok.com/@miyanann", "_blank");
  };

  const handleClickWhatsapp = () => {
    window.open("https://wa.me/+62881081917409", "_blank");
  };

  const handleClickTelegram = () => {
    window.open("https://t.me/digipopo", "_blank");
  };

  return (
    <>
      <FooterContainer>
        <Grid container columnSpacing={3} rowSpacing={4}>
          <Grid item xs={12} sm={5} md={6} lg={5}>
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ImageCustom
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/icons/logo-miya-tiktok.png"
                  }
                  alt="Miya TikTok"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ marginRight: "1rem" }}>
                  Website top-up terlengkap, tercepat, termurah, dan terpercaya
                  di Indonesia. Hanya dalam waktu 1 detik kredit permainan akan
                  ditambahkan ke dalam akunmu secara instan. Top up Mobile
                  Legends, Free Fire, Ragnarok M, dan berbagai game lainnya
                  segera!
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={7}>
            <Grid container columnSpacing={3} rowSpacing={4}>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Menu kami</TitleParagraph>
                <BodyParagraph>
                  <CustomButtonFooter>
                    <Link to={menuUrl.base} style={{ textDecoration: "none" }}>
                      <LeftBox
                        sx={{
                          color:
                            pathname === menuUrl.base
                              ? "primary.main"
                              : "text.secondary",
                        }}
                      >
                        <HomeIcon
                          sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                        />
                        Home
                      </LeftBox>
                    </Link>
                  </CustomButtonFooter>
                  <CustomButtonFooter>
                    <Link
                      to={menuUrl.riwayatPesanan}
                      style={{ textDecoration: "none" }}
                    >
                      <LeftBox
                        sx={{
                          color:
                            pathname === menuUrl.riwayatPesanan
                              ? "primary.main"
                              : "text.secondary",
                        }}
                      >
                        <ReceiptLongIcon
                          sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                        />
                        Riwayat Transaksi
                      </LeftBox>
                    </Link>
                  </CustomButtonFooter>
                  <CustomButtonFooter>
                    <Link
                      to={menuUrl.cekPesanan}
                      style={{ textDecoration: "none" }}
                    >
                      <LeftBox
                        sx={{
                          color: pathname.includes(menuUrl.cekPesanan)
                            ? "primary.main"
                            : "text.secondary",
                        }}
                      >
                        <ContentPasteSearch
                          sx={{ marginRight: "0.3rem", fontSize: "1.3rem" }}
                        />
                        Cek Pesanan
                      </LeftBox>
                    </Link>
                  </CustomButtonFooter>
                </BodyParagraph>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Ikuti akun kami</TitleParagraph>
                <BodyParagraph>
                  {/* <CustomButtonFooter onClick={handleClickInstagram}>
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-instagram.png"
                      }
                    />
                    Instagram
                  </CustomButtonFooter> */}
                  <CustomButtonFooter onClick={handleClickTiktok}>
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL + "/assets/icons/logo-tiktok.png"
                      }
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "0.25rem",
                      }}
                    />
                    TikTok
                  </CustomButtonFooter>
                </BodyParagraph>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Saluran pembayaran</TitleParagraph>
                <Grid container columnSpacing={1.5} rowSpacing={2}>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bca.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bni.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bri.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL + "/assets/images/mandiri.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/bsi.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/permata-bank.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/qris.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/ovo.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={process.env.PUBLIC_URL + "/assets/images/dana.png"}
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL + "/assets/images/gopay.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL + "/assets/images/linkaja.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                  <Grid item xs={6}>
                    <PaperBank>
                      <ImagePembayaran
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/shopeepay.png"
                        }
                      />
                    </PaperBank>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <TitleParagraph>Butuh bantuan?</TitleParagraph>
                <BodyParagraph>
                  <CustomButton
                    buttonProps={{
                      onClick: handleClickWhatsapp,
                      sx: { width: "100%", marginBottom: "1rem" },
                    }}
                  >
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-whatsapp.png"
                      }
                    />
                    WhatsApp
                  </CustomButton>
                  <CustomButton
                    buttonProps={{
                      onClick: handleClickTelegram,
                      sx: { width: "100%" },
                    }}
                  >
                    <ImageBantuan
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icons/logo-telegram.png"
                      }
                    />
                    Telegram
                  </CustomButton>
                </BodyParagraph>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FooterContainer>
      <CopyrightContainer>
        2024 Miya Lapak &copy; All Rights Reserved.
      </CopyrightContainer>
    </>
  );
}

export default Footer;
