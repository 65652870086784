/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ContentPasteSearch from "@mui/icons-material/ContentPasteSearch";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Collapse from "@mui/material/Collapse";
import { Link, useLocation } from "react-router-dom";

import {
  CustomAppBar,
  CustomChildToolbar,
  CustomToolbar,
  ImageBrand,
  ImageCustom,
  ListSearchMenu,
  ListSearchMenuItem,
  MenuIcon,
  MenuIconButton,
  NavbarMenu,
  NavbarMenuWrapper,
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "./Navbar.styles";
import { CenteredBox, LeftBox, RightBox } from "styles";
import { menuUrl } from "constants/";
import { useMediaQuery } from "react-responsive";
import { NavbarMenuItemProps } from "./Navbar.types";
import { BrandsData, useLazyGetBrandsQuery } from "api/customer";
import { responseErrorHandler, transformUnderscoreToUrl } from "utils";
import { useOutsideDiv } from "hooks";
import { SearchIcon } from "assets/icons";

function NavbarMenuItem({
  pathname,
  setIsOpenChildNavbar,
}: NavbarMenuItemProps) {
  return (
    <NavbarMenuWrapper>
      <Link
        to={menuUrl.base}
        style={{ textDecoration: "none" }}
        onClick={() => setIsOpenChildNavbar(false)}
      >
        <NavbarMenu
          sx={{
            color: pathname === menuUrl.base ? "primary.main" : "text.secondary",
          }}
        >
          <HomeIcon sx={{ marginRight: "5px" }} />
          Home
        </NavbarMenu>
      </Link>
      <Link
        to={menuUrl.riwayatPesanan}
        style={{ textDecoration: "none" }}
        onClick={() => setIsOpenChildNavbar(false)}
      >
        <NavbarMenu
          sx={{
            color:
              pathname === menuUrl.riwayatPesanan
                ? "primary.main"
                : "text.secondary",
          }}
        >
          <ReceiptLongIcon sx={{ marginRight: "5px" }} /> Riwayat Transaksi
        </NavbarMenu>
      </Link>
      <Link
        to={menuUrl.cekPesanan}
        style={{ textDecoration: "none" }}
        onClick={() => setIsOpenChildNavbar(false)}
      >
        <NavbarMenu
          sx={{
            color: pathname.includes("/pesanan")
              ? "primary.main"
              : "text.secondary",
          }}
        >
          <ContentPasteSearch sx={{ marginRight: "5px" }} /> Cek Pesanan
        </NavbarMenu>
      </Link>
    </NavbarMenuWrapper>
  );
}

export default function Navbar() {
  // React route state
  const { pathname } = useLocation();

  // React responsive state
  const isTabAndMobile = useMediaQuery({ maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  // This state
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpenChildNavbar, setIsOpenChildNavbar] = useState<boolean>(false);
  const [isOpenListSearch, setIsOpenListSearch] = useState<boolean>(false);
  const [listShowBrands, setListShowBrands] = useState<BrandsData[]>([]);

  const profileRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  useOutsideDiv(wrapperRef, profileRef, setIsOpenListSearch);

  // Queries state
  const [getBrands, { data: dataBrands }] = useLazyGetBrandsQuery();

  console.log("searchValue : ", searchValue);

  useLayoutEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    console.log("isTabAndMobile", isTabAndMobile);
    if (!isTabAndMobile && isOpenChildNavbar) setIsOpenChildNavbar(false);
  }, [isTabAndMobile]);

  const fetchBrands = async () => {
    try {
      const res = await getBrands().unwrap();
      console.log("res dari fetchBrands", res);
    } catch (error: any) {
      responseErrorHandler(error);
    }
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(event.target.value);

    if (event.target.value.length > 0 && !isOpenListSearch) {
      setIsOpenListSearch(true);
    } else if (event.target.value.length === 0) {
      setIsOpenListSearch(false);
      return;
    }

    if (!dataBrands) return;

    let newListShowBrands: BrandsData[] = [];
    dataBrands.forEach((obj) => {
      if (!obj.active) return;
      if (
        obj.name
          .toLocaleLowerCase()
          .includes(event.target.value.toLocaleLowerCase()) ||
        obj.brand
          .toLocaleLowerCase()
          .includes(event.target.value.toLocaleLowerCase())
      ) {
        newListShowBrands = [...newListShowBrands, obj];
      }
    });

    setListShowBrands(newListShowBrands);
  };

  const renderSearchNavbar = () => {
    return (
      <CenteredBox sx={{ position: "relative" }}>
        <Search ref={profileRef}>
          <SearchIconWrapper>
            <SearchIcon fill="#000000" />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Cari Game Favorit Anda..."
            inputProps={{ "aria-label": "search" }}
            value={searchValue}
            onChange={(event) => handleChangeInput(event)}
          />
        </Search>
        {isOpenListSearch && (
          <ListSearchMenu ref={wrapperRef}>
            {listShowBrands.length === 0 && (
              <CenteredBox sx={{ height: "3rem" }}>
                Data Tidak Ditemukan
              </CenteredBox>
            )}
            {listShowBrands.map((obj) => {
              return (
                <Link
                  to={menuUrl.detailBrandUrl(
                    transformUnderscoreToUrl(obj.brand)
                  )}
                  style={{ textDecoration: "none" }}
                >
                  <ListSearchMenuItem
                    onClick={() => setIsOpenListSearch(false)}
                  >
                    <LeftBox>
                      <ImageBrand src={obj.iconUrl} />
                      <LeftBox>{obj.name}</LeftBox>
                    </LeftBox>
                  </ListSearchMenuItem>
                </Link>
              );
            })}
          </ListSearchMenu>
        )}
      </CenteredBox>
    );
  };

  return (
    <CustomAppBar position="sticky">
      <>
        <CustomToolbar>
          {/* Left navbar */}
          <Link to={menuUrl.base} style={{ textDecoration: "none" }}>
            <LeftBox sx={{ cursor: "pointer" }}>
              <ImageCustom
                src={
                  process.env.PUBLIC_URL +
                  "/assets/icons/logo-tulisan-miya-tiktok.png"
                }
                alt="Miya TikTok"
              />
            </LeftBox>
          </Link>

          {/* Center navbar */}
          {!isMobile && renderSearchNavbar()}

          {/* Right navbar */}
          <RightBox>
            {isTabAndMobile && (
              <MenuIconButton
                size="large"
                edge="start"
                onClick={() => setIsOpenChildNavbar(!isOpenChildNavbar)}
              >
                <MenuIcon />
              </MenuIconButton>
            )}
            {!isTabAndMobile && (
              <NavbarMenuItem
                pathname={pathname}
                setIsOpenChildNavbar={setIsOpenChildNavbar}
              />
            )}
          </RightBox>
        </CustomToolbar>
        <Collapse in={isOpenChildNavbar}>
          <CustomChildToolbar>
            {isMobile && renderSearchNavbar()}
            <NavbarMenuItem
              pathname={pathname}
              setIsOpenChildNavbar={setIsOpenChildNavbar}
            />
          </CustomChildToolbar>
        </Collapse>
      </>
    </CustomAppBar>
  );
}
